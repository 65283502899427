import dayjs from 'dayjs'
export const isBrowser = (): boolean => typeof window !== 'undefined'

export const makeKebabCase = (str: string): string => {
  return str.toLowerCase().split(' ').join('-')
}

export const throttle = (callback: Function, wait: number): (() => void) => {
  let isWaiting = false

  return (): void => {
    if (!isWaiting) {
      callback()
      isWaiting = true
      setTimeout(function () {
        isWaiting = false
      }, wait)
    }
  }
}

export const makeArrayTypeString = (
  typeOrFormat: string | undefined
): string | undefined => {
  return typeOrFormat ? `${typeOrFormat}[]` : undefined
}

export const dateFormatter = (time: number): string => {
  return dayjs(time).format('MMMM D, YYYY')
}

export const objIsEmpty = (obj: { [key: string]: any }): boolean => {
  return Object.keys(obj).length === 0
}
