import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WebexCCLogo from '../assets/svgs/webex-cc-logo.svg'
import { IResource } from '../interfaces/resourceInterfaces'
import Page404 from '../pages/404'
import { logError } from '../services/loggerService'
import {
  completeBootstrap,
  loginUserAction,
  retrieveBootstrappingState,
  retrievePermittedData,
  setAuthFailure,
  setPermittedData,
  setTokenAction,
} from '../state/auth'
import { AppState } from '../state/store'
import {
  getAccessToken,
  getRedirectLocation,
  LOGGED_IN,
  LOGGED_OUT,
  setAuthenticatedState,
} from '../utils/auth.utils'

interface LoadingLayoutProps {
  docType: string
  docName: string
  path: string
  uuid: string
}

const LoadingLayout: React.FC<LoadingLayoutProps> = ({
  children,
  path,
  docType,
  docName,
  uuid,
}) => {
  const strapiData = useStaticQuery(graphql`
    query {
      allStrapiFeatureFlags {
        nodes {
          name
        }
      }
      allStrapiGuides(sort: { fields: name, order: ASC }) {
        nodes {
          featureFlag {
            name
          }
          name
          section
          strapiId
        }
      }
      allStrapiPartnerShowCases(sort: { fields: name, order: ASC }) {
        nodes {
          featureFlag {
            name
          }
          name
          strapiId
        }
      }
      allStrapiResources(sort: { fields: name, order: ASC }) {
        nodes {
          name
          rests {
            name
            id
          }
          grpcs {
            name
            id
          }
          webhooks {
            name
            id
          }
          graphqls {
            name
            id
          }
        }
      }
      allStrapiRests {
        nodes {
          name
          strapiId
          featureFlag {
            name
          }
          version
        }
      }
      allStrapiGrpcs {
        nodes {
          name
          strapiId
          featureFlag {
            name
          }
          version
        }
      }
      allStrapiWebhooks {
        nodes {
          name
          strapiId
          featureFlag {
            name
          }
          version
        }
      }
      allStrapiGraphqls {
        nodes {
          name
          strapiId
          featureFlag {
            name
          }
          version
        }
      }
    }
  `)
  const dispatch = useDispatch()
  const [redirecting, setRedirecting] = useState(false)
  const [currentlyBootstrapping, setCurrentlyBootstrapping] = useState(false)

  useEffect(() => {
    // if path changes, we could have been redirected and are loading new page, so reset value to remove loading indicator
    setRedirecting(false)
  }, [path])

  const bootstrappingAuthenticatedApp = useSelector((state: AppState) =>
    retrieveBootstrappingState(state)
  )
  const permittedData = useSelector((state: AppState) =>
    retrievePermittedData(state)
  )

  if (!Object.keys(permittedData).length) {
    dispatch(setPermittedData(strapiData, {}))
  }
  const flattenedFeatureFlagList = strapiData.allStrapiFeatureFlags.nodes.map(
    (obj: { name: string }) => obj.name
  )

  useEffect(() => {
    const bootstrap = async (): Promise<void> => {
      setCurrentlyBootstrapping(true)
      try {
        const token = await getAccessToken()
        if (token) {
          setAuthenticatedState(LOGGED_IN)
          dispatch(setTokenAction(token))
          await dispatch(
            loginUserAction(token, flattenedFeatureFlagList, strapiData)
          )
          const redirectLocation = getRedirectLocation()
          if (redirectLocation) {
            setRedirecting(true)
            navigate(redirectLocation)
          }
        } else {
          setAuthenticatedState(LOGGED_OUT)
        }
      } catch (e) {
        logError(`Error bootstrapping authenticated app: ${e.toString()}`)
        dispatch(setAuthFailure())
      } finally {
        dispatch(completeBootstrap())
        setCurrentlyBootstrapping(false)
      }
    }

    if (bootstrappingAuthenticatedApp && !currentlyBootstrapping) {
      bootstrap()
    }
  }, [
    dispatch,
    bootstrappingAuthenticatedApp,
    flattenedFeatureFlagList,
    strapiData,
    currentlyBootstrapping,
  ])

  const determineWhatToDisplay = (): any => {
    const shouldBlockPage = (): boolean => {
      if (docType === 'resources') {
        return !permittedData.resources.filter(
          (res: IResource) => res.name === docName
        ).length
      } else if (docType) {
        return permittedData[docType].find(
          ({ strapiId }: { [key: string]: number }) => {
            return strapiId === Number(uuid)
          }
        )
          ? false
          : true
      } else {
        return false
      }
    }

    if (shouldBlockPage()) {
      return <Page404 />
    } else {
      return children
    }
  }

  return bootstrappingAuthenticatedApp ||
    redirecting ||
    !Object.keys(permittedData).length ? (
    <div className="loading-screen">
      <WebexCCLogo className="loading-stuff" />
    </div>
  ) : (
    determineWhatToDisplay()
  )
}

export default LoadingLayout
