import {
  Avatar,
  Button,
  Icon,
  List,
  ListItem,
  Popover,
} from '@momentum-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexWordMark from '../../assets/svgs/webex-cc-text-logo.svg'
import { retrieveAuthFailureState, retrieveUser } from '../../state/auth'
import { AppState } from '../../state/store'
import { login, logout } from '../../utils/auth.utils'
import { User } from '../../interfaces/ciInterfaces'
import ErrorAlert from '../ErrorAlert'
import Link from '../Link'
import MobileMenu from './MobileMenu'
import { navigate } from 'gatsby'

interface IHeaderProps {
  handleSideNavToggle?: Function
}

export const renderPhotoOrInitials = (user: User): JSX.Element => {
  if (!user.photos) {
    return (
      <Avatar
        data-cy="userAvatar"
        title={`${user?.name?.givenName || 'unknown'} ${
          user?.name?.familyName || 'unknown'
        }`}
      />
    )
  } else {
    const avatar = user.photos
      .filter((photo) => photo.type === 'photo')
      .map((el) => el.value)
      .reduce((url) => url)

    return <Avatar data-cy="userAvatar" src={avatar} />
  }
}

const Header: React.FC<IHeaderProps> = ({ handleSideNavToggle }) => {
  const { t } = useTranslation()
  const user = useSelector((state: AppState) => retrieveUser(state))

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)
  const [showAuthAlert, setShowAuthAlert] = useState(false)
  const hasAuthError = useSelector((state: AppState) =>
    retrieveAuthFailureState(state)
  )

  const onMobileSideNavClick = (showSideNav: boolean): void => {
    setIsMobileNavVisible(showSideNav)
  }

  useEffect(() => {
    if (Object.keys(user).length) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
      if (hasAuthError) {
        setShowAuthAlert(true)
      }
    }
  }, [hasAuthError, user])

  const loginButton = (): JSX.Element => {
    return (
      <Button
        data-cy="loginButton"
        onClick={login}
        ariaLabel="Log In"
        className="login-button-desktop"
        size={52}
      >
        {t('header.login')}
      </Button>
    )
  }

  const userIcon = (): JSX.Element => {
    const routeToApps = () => {
      navigate('/my-apps')
    }
    const content = (
      <List>
        <ListItem
          className="menu-button"
          label={t('header.myApps')}
          onClick={routeToApps}
        />
        <ListItem
          data-cy="logoutButton"
          className="menu-button"
          label={t('header.logout')}
          onClick={logout}
        />
      </List>
    )
    return (
      <div className="md-top-bar__user">
        <Popover
          content={content}
          popoverTrigger={'MouseEnter'}
          direction={'bottom-center'}
        >
          {renderPhotoOrInitials(user)}
        </Popover>
      </div>
    )
  }

  const enderLoginButtonOrUserIcon = (): JSX.Element => {
    return isLoggedIn ? userIcon() : loginButton()
  }

  const renderMobileMenuOrHamburgerIcon = (): JSX.Element => {
    return isMobileNavVisible ? (
      <MobileMenu
        isUserLoggedIn={isLoggedIn}
        handleMobileSideNavClick={onMobileSideNavClick}
      />
    ) : (
      <Button
        data-cy="mobileMenuButton"
        className="md-top-bar__mobile-menu-button md-button--icon"
        onClick={(): void => {
          onMobileSideNavClick(true)
        }}
        ariaLabel="Mobile Menu"
      >
        <Icon name="icon-list-menu_20" />
      </Button>
    )
  }
  return (
    <header className="md-top-bar md-top-bar--dark header">
      <div className="header-wrapper">
        <div data-cy="headerLogo" className="md-top-bar__container">
          {handleSideNavToggle ? (
            <Button
              data-cy="toggleSideNavButton"
              className="md-button--dark-gray hidden-sm hidden-xs toggle-side-nav-btn"
              onClick={(): void => {
                handleSideNavToggle()
              }}
              ariaLabel="Side Menu"
              circle
            >
              <Icon name="icon-list-menu_24" />
            </Button>
          ) : null}
          <div className="md-top-bar__brand top-bar-brand">
            <Link
              className="md-brand"
              to="/"
              aria-label={t('header.webexContactCenter')}
            >
              <WebexWordMark alt={t('header.webexContactCenter')} />
            </Link>
          </div>
          <nav
            data-cy="headerNav"
            className="md-top-bar__nav header-navigation"
            aria-label="header-navigation"
          >
            <div
              data-cy="navListItemDocumentation"
              className="md-list md-list--horizontal"
              role="navigation"
              aria-label="header navigation"
            >
              <Link
                activeClassName="md-list-item active"
                partiallyActive={true}
                className="md-list-item navigation-desktop-list-item"
                to="/documentation/getting-started"
                pathToMatch="/documentation/"
              >
                {t('header.documentation')}
              </Link>
            </div>
          </nav>
          <div className="md-top-bar__right">
            {enderLoginButtonOrUserIcon()}
          </div>
          {renderMobileMenuOrHamburgerIcon()}
          <div role="none" className="md-tb-mobile__mask"></div>
        </div>
        <ErrorAlert
          title={t('header.loginFailedTitle')}
          showAlert={showAuthAlert}
          handleClick={setShowAuthAlert}
        />
      </div>
    </header>
  )
}

export default Header
