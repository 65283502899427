import mixpanel from 'mixpanel-browser'
import { IFeatureFlag } from '../interfaces/featureFlagInterfaces'
import { isBrowser } from '../utils/general.utils'
export default class MetricsService {
  private static instance: MetricsService

  private areMetricsEnabled = false

  private setAreMetricsEnabled(userFeatureFlags: IFeatureFlag): void {
    const hasValidFeatureFlag = (): boolean => {
      if (process.env.GATSBY_METRICS_NEEDS_FEATURE_FLAG === 'true') {
        return Object.entries(userFeatureFlags).some(
          (featureFlag) =>
            featureFlag[0] ===
              process.env.GATSBY_METRICS_SERVICE_FEATURE_FLAG &&
            featureFlag[1] === 'on'
        )
      }
      return true
    }

    this.areMetricsEnabled =
      isBrowser() &&
      process.env.NODE_ENV !== 'development' &&
      hasValidFeatureFlag()
  }

  public getAreMetricsEnabled(): boolean {
    return this.areMetricsEnabled
  }

  private constructor(userFeatureFlags?: IFeatureFlag) {
    if (userFeatureFlags) {
      this.setAreMetricsEnabled(userFeatureFlags)
    }

    if (this.getAreMetricsEnabled()) {
      mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN as string)
    }
  }

  static getInstance(userFeatureFlags?: IFeatureFlag): MetricsService {
    if (!MetricsService.instance) {
      this.instance = new MetricsService(userFeatureFlags)
    }
    return this.instance
  }

  public track(name: string, properties: { [key: string]: any }): void {
    if (this.getAreMetricsEnabled()) {
      mixpanel.track(name, properties)
    }
  }

  public trackExternalLinkVisited(
    anchorTag: string,
    properties: { ['name']: string }
  ): void {
    if (this.getAreMetricsEnabled()) {
      mixpanel.track_links(anchorTag, 'External Link Visited', properties)
    }
  }

  public optUserOutOfTracking(): void {
    if (this.getAreMetricsEnabled()) {
      mixpanel.opt_out_tracking()
    }
  }

  public optUserIntoTracking(): void {
    if (this.getAreMetricsEnabled()) {
      mixpanel.opt_in_tracking()
    }
  }

  public hasUserOptedInOrOut(): boolean | void {
    if (this.getAreMetricsEnabled()) {
      const userOptedIn = mixpanel.has_opted_in_tracking()
      const userOptedOut = mixpanel.has_opted_out_tracking()

      return userOptedIn || userOptedOut
    }
  }
}
