/* eslint-disable @typescript-eslint/camelcase */
import qs from 'qs'
import { User } from '../interfaces/ciInterfaces'
import httpService from '../services/httpService'
import { logError } from '../services/loggerService'
import { isBrowser } from './general.utils'

export const REDIRECT_LOCATION = 'locationToRedirectUsersTo'
export const LOADING_STATE = 'loadingState'
export const PERFORMING_LOGIN_PROCESS = 'loggingIn'
export const AUTHENTICATED_STATE = 'authedState'
export const LOGGED_IN = 'loggedIn'
export const LOGGED_OUT = 'loggedOut'
const auth_token = 'auth_token'
const CI_URLS_KEY = 'ci_urls'
let idBrokerUrl: string
let identityUrl: string

if (isBrowser()) {
  if (localStorage.getItem(CI_URLS_KEY)) {
    const urls = JSON.parse(localStorage.getItem(CI_URLS_KEY)!)
    ;({ idBrokerUrl, identityUrl } = urls)
  } else {
    idBrokerUrl = process.env.ID_BROKER_URL as string
  }
}

const authHeader = `Basic ${Buffer.from(
  `${process.env.GATSBY_AUTH_CLIENT_ID}:${process.env.GATSBY_AUTH_CLIENT_SECRET}`
).toString('base64')}`

export const login = (): void => {
  setLoadingState(PERFORMING_LOGIN_PROCESS)
  setRedirectLocation()

  const query = qs.stringify({
    response_type: 'code',
    client_id: process.env.GATSBY_AUTH_CLIENT_ID,
    scope: process.env.AUTH_SCOPE,
    redirect_uri: window.location.origin,
  })

  window.location.replace(`${idBrokerUrl}/idb/oauth2/v1/authorize?${query}`)
}

const clearStorage = (): void => {
  localStorage.removeItem(auth_token)
  localStorage.removeItem(CI_URLS_KEY)
  localStorage.removeItem('bff_base_url')
}

export const logout = (): void => {
  const token = JSON.parse(localStorage.getItem(auth_token) as string).token
    ?.access_token
  clearStorage()
  const query = qs.stringify({
    token,
    goto: window.location.origin,
  })
  window.location.replace(`${idBrokerUrl}/idb/oauth2/v1/logout?${query}`)
}

const fetchClusterDetails = async (code: string): Promise<void> => {
  const clusterId = code.split('_')[1]

  const fls = await httpService.post(
    `${idBrokerUrl}/idb/oauth2/v1/access_token`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: authHeader,
      },
      params: {
        grant_type: 'client_credentials',
        scope: 'Identity:SCIM',
        self_contained_token: 'true',
      },
    }
  )

  const res = await httpService.get(
    `${process.env.FLS_URL}/api/v1/Clusters/${clusterId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${fls.data.access_token}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    }
  )

  ;({ idBrokerUrl, identityUrl } = res.data.clusterLocationEndpoints)

  localStorage.setItem(
    CI_URLS_KEY,
    JSON.stringify({
      idBrokerUrl,
      identityUrl,
    })
  )
}

export const getAccessToken = async (): Promise<string | null> => {
  const cachedToken: string | null = localStorage.getItem(auth_token)
  if (cachedToken) {
    const parsedToken: ITokenCache = JSON.parse(cachedToken)
    const isAccessTokenExpired =
      millisecondsBeforeExpiration(parsedToken).ofToken <= 0
    const isRefreshTokenExpired =
      millisecondsBeforeExpiration(parsedToken).ofRefreshToken <= 0

    if (!isAccessTokenExpired) {
      startTokenRotation(parsedToken)
      return parsedToken.token.access_token
    } else if (!isRefreshTokenExpired) {
      const tokenRefresh = await requestToken({
        refresh: parsedToken.token.refresh_token,
      })
      if (tokenRefresh) {
        startTokenRotation(tokenRefresh)
        return tokenRefresh.token.access_token
      } else {
        return null
      }
    } else {
      clearStorage()
    }
  }

  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('code')

  if (code) {
    window.history.replaceState(null, '', window.location.origin)

    try {
      await fetchClusterDetails(code)
    } catch (e) {
      logError(`Failed to fetch cluster details: ${JSON.stringify(e)}`)
      return null
    }

    const tokenObj = await requestToken({ code })
    if (tokenObj) {
      await startTokenRotation(tokenObj)
      return tokenObj.token.access_token
    } else {
      return null
    }
  }

  return null
}

export const getDataCenter = async (token: string): Promise<any> => {

  const map = {
    QA: 'https://devportal-bff.devus1.ciscoccservice.com/',
    US1: 'https://devportal-bff.produs1.ciscoccservice.com/',
    EU1: 'https://devportal-bff.prodeu1.ciscoccservice.com/',
    EU2: 'https://devportal-bff.prodeu2.ciscoccservice.com/',
    ANZ1: 'https://devportal-bff.prodanz1.ciscoccservice.com/',
    CA1: 'https://devportal-bff.prodca1.ciscoccservice.com/'
  }

   const response = await httpService.get(
    `https://u2c.wbx2.com/u2c/api/v1/user/catalog`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  
  console.log('this is response', response)

  const id = response.data.services.filter((services)=>{
    return services.serviceName.includes("wcc")
  })[0].id.split("urn:WCC:")[1].split(":")[0]
  
  console.log('this is id', id)

  const datacenter = map[id]

  console.log('datacenter', datacenter)

  localStorage.setItem("bff_base_url", datacenter)
}

export const requestToken = async (
  requestTokenBy: { refresh: string } | { code: string }
): Promise<ITokenCache | null> => {
  const req = httpService.CancelToken.source()
  const timeout = window.setTimeout(() => req.cancel(), 10000)
  return httpService
    .post(`${idBrokerUrl}/idb/oauth2/v1/access_token`, null, {
      cancelToken: req.token,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: authHeader,
      },
      params:
        // check to see if we are requesting an access token
        // using refreshToken or login auth code
        'code' in requestTokenBy
          ? {
              grant_type: 'authorization_code',
              redirect_uri: window.location.origin,
              code: requestTokenBy.code,
              self_contained_token: 'true',
            }
          : {
              grant_type: 'refresh_token',
              refresh_token: requestTokenBy.refresh,
              self_contained_token: 'true',
            },
    })
    .then((res) => {
      const token: IToken = { ...res.data }

      const cache: ITokenCache = { token, timestamp: Date.now() }
      localStorage.setItem(auth_token, JSON.stringify(cache))

      getDataCenter(token.access_token)

      clearTimeout(timeout)
      return cache
    })
    .catch((err) => {
      if (httpService.isCancel(err)) {
        logError('Token Request error: timeout')
      } else {
        logError(
          `Token Request error: ${JSON.stringify(
            err?.response?.data || err?.message || err
          )}`
        )
      }

      clearTimeout(timeout)
      return null
    })
}

let timeout: number | null = null

export const startTokenRotation = (tokenObj: ITokenCache): void => {
  if (timeout !== null) {
    clearTimeout(timeout)
    timeout = null
  }
  // Per UUIP: 75% of expiration time to be taken for offset
  const time =
    millisecondsBeforeExpiration(tokenObj).ofToken -
    tokenObj.token.expires_in * 1000 * 0.75
  timeout = window.setTimeout(
    async () => {
      timeout = null
      const cache = localStorage.getItem(auth_token)
      if (cache) {
        const cachedToken: ITokenCache = JSON.parse(cache)
        const isRefreshTokenExpired =
          millisecondsBeforeExpiration(cachedToken).ofRefreshToken <= 0
        if (!isRefreshTokenExpired) {
          const tokenRefresh = await requestToken({
            refresh: cachedToken.token.refresh_token,
          })
          if (tokenRefresh) {
            startTokenRotation(tokenRefresh)
          } else {
            logError(
              'Could not refresh OAuth token. requestToken function fail.'
            )
          }
        } else {
          logError('Could not refresh OAuth token. refresh_token expired.')
        }
      } else {
        logError('Could not refresh OAuth token. No token in localStorage.')
      }
    },
    time < 0 ? 0 : time
  )
}

const millisecondsBeforeExpiration = (
  tokenObj: ITokenCache
): ITokenExpiration => {
  const passed = Date.now() - tokenObj.timestamp
  return {
    ofToken: tokenObj.token.expires_in * 1000 - passed,
    ofRefreshToken: tokenObj.token.refresh_token_expires_in * 1000 - passed,
  }
}

export const getUser = async (token: string): Promise<User> => {
  const response = await httpService.get(
    `${process.env.GATSBY_BFF_BASE_URL}v1/identity`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        identityHost: identityUrl,
      },
    }
  )
  return response.data
}

export const setLoadingState = (state: string): void => {
  if (isBrowser()) {
    localStorage.setItem(LOADING_STATE, state)
  }
}

export const getLoadingState = (): string | null | undefined => {
  if (isBrowser()) {
    const loadingState = localStorage.getItem(LOADING_STATE)
    localStorage.removeItem(LOADING_STATE)
    return loadingState
  }
}

export const setAuthenticatedState = (state: string): void => {
  if (isBrowser()) {
    localStorage.setItem(AUTHENTICATED_STATE, state)
  }
}

export const isUserAuthenticated = (): boolean | undefined => {
  if (isBrowser()) {
    return localStorage.getItem(AUTHENTICATED_STATE) === LOGGED_IN
  }
}

export const shouldWeBootstrapAuthenticatedApp = (): boolean => {
  return (
    getLoadingState() === PERFORMING_LOGIN_PROCESS || !!isUserAuthenticated()
  )
}

export const setRedirectLocation = (): void => {
  if (isBrowser() && window.location.pathname !== '/') {
    localStorage.setItem(REDIRECT_LOCATION, window.location.pathname)
  }
}

export const getRedirectLocation = (): string | null | undefined => {
  if (isBrowser()) {
    const redirectLocation = localStorage.getItem(REDIRECT_LOCATION)
    localStorage.removeItem(REDIRECT_LOCATION)
    return redirectLocation
  }
}

export const getCIUrls = (): { [key: string]: string } => {
  return { identityUrl, idBrokerUrl }
}

interface IToken {
  access_token: string
  expires_in: number
  refresh_token: string
  refresh_token_expires_in: number
}

interface ITokenCache {
  token: IToken
  timestamp: number
}

interface ITokenExpiration {
  ofToken: number
  ofRefreshToken: number
}