import { isBrowser } from '../utils/general.utils'

export const LOGS_KEY = 'devportal_logs'

interface LogMessage {
  timestamp: string
  level: string
  message: string
  service: string
}

const log = ({ message, level }: { message: string; level: string }): void => {
  if (isBrowser()) {
    const existingLogs = localStorage.getItem(LOGS_KEY)
    const newLogMessage = {
      timestamp: new Date().toISOString(),
      level,
      message,
      service: 'devportal',
    }
    let messageBuffer: LogMessage[]

    if (existingLogs) {
      const parsedLogs = JSON.parse(existingLogs)
      parsedLogs.push(newLogMessage)
      messageBuffer = parsedLogs
    } else {
      messageBuffer = [newLogMessage]
    }

    localStorage.setItem(LOGS_KEY, JSON.stringify(messageBuffer))
  }
}

export const logInfo = (message: string): void => {
  log({ message, level: 'info' })
}

export const logError = (message: string): void => {
  log({ message, level: 'error' })
}
