import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export const generateTrackingId = (): string => {
  const TRACKING_ID_SENDER_TYPE = 'DP'
  return `${TRACKING_ID_SENDER_TYPE}_${uuidv4()}`
}

axios.interceptors.request.use((request) => {
  request.headers['trackingId'] = generateTrackingId()
  return request
})

export default axios
