import React from 'react'
import { useInterval } from '../../hooks/useInterval'
import httpService from '../../services/httpService'
import { LOGS_KEY } from '../../services/loggerService'
import { getAccessToken, getCIUrls } from '../../utils/auth.utils'
import { isBrowser } from '../../utils/general.utils'

export const fiveMinutes = 300000

const LogSender: React.FC = ({ children }): any => {
  const { idBrokerUrl } = getCIUrls()

  useInterval(async () => {
    if (isBrowser()) {
      const storedLogs = localStorage.getItem(LOGS_KEY)
      const token = await getAccessToken()

      if (storedLogs && token) {
        localStorage.setItem(LOGS_KEY, '')
        const parsedLogs = JSON.parse(storedLogs)

        const httpConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }

        httpService.post(
          `${process.env.GATSBY_BFF_BASE_URL}logs`,
          { logs: parsedLogs, idBrokerHost: idBrokerUrl },
          httpConfig
        )
      }
    }
  }, fiveMinutes)

  return children
}

export default LogSender
